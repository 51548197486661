import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { dataList } from "./constanst";

interface IProject {
  project_id: number;
  project_slug: string;
  project_title: string;
  like_count: number;
  main_image: string;
  main_image_wide: string;
  main_image_long: string;
  project_status: number;
  like_check: number;
}

function App() {
  const [images, setImages] = useState<IProject[]>([]);

  const idxNumber = useRef(0);

  const fetchProductList = async () => {
    setImages(dataList.flat());
    // idxNumber.current++;
  };

  // 20개씩 가져와 보여주는 방식
  const fetchData = async () => {
    // setImages((prev) => [...prev, ...dataList[idxNumber.current]]);
    // idxNumber.current++;
  };

  const baseUrl = "https://cdn.shapl.com/design_project/main_img";
  const [errorImages, setErrorImages] = useState<Record<string, boolean>>({});

  const handleError = (src: string) => {
    setErrorImages((prev) => ({
      ...prev,
      [src]: true, // 특정 이미지가 로드되지 않으면 상태 업데이트
    }));
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  return (
    <div className="bg-white flex flex-col min-w-[360px]">
      <header className="z-10 w-full sticky top-0 bg-white border-b-[1px]">
        <div className="flex flex-1 h-16 justify-center">
          <div className="flex flex-1 justify-between">
            <div className="flex items-center justify-center">
              <button>
                <div className="w-[50px] flex items-center justify-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/nav/icon-menu-burger.svg`}
                    className="w-[18px]"
                    alt="Shapl"
                  />
                </div>
              </button>

              <button>
                <div className="w-[50px] flex items-center justify-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/nav/icon-menu-search.svg`}
                    className="w-[18px]"
                    alt="Shapl"
                  />
                </div>
              </button>
            </div>

            <button>
              <img
                src={`${process.env.PUBLIC_URL}/nav/icon-shapl-logo.svg`}
                className="w-[102px]"
                alt="Shapl"
              />
            </button>

            <div className="flex items-center justify-center">
              <button>
                <div className="w-[50px] flex items-center justify-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/nav/icon-menu-store.svg`}
                    className="w-[18px]"
                    alt="Shapl"
                  />
                </div>
              </button>

              <button>
                <div className="w-[50px] flex items-center justify-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/nav/icon-menu-mypage.svg`}
                    className="w-[18px]"
                    alt="Shapl"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="flex justify-center whitespace-nowrap overflow-x-auto h-[140px] sm:h-[200px] items-center bg-gradient-to-r from-[#906dff] to-[#466dff] via-[#466dff] hidescroll">
        <div className="h-full flex justify-center items-center relative">
          <div className="bg-white w-[260px] h-[86px] sm:w-[470px] sm:h-[120px] rounded-2xl items-center flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/banner/ment02@2x.png`}
              className="h-[36px] sm:h-[48px] object-contain bg-white"
              alt=""
            />
          </div>
        </div>

        <div className="h-full flex justify-center items-center relative mx-[70px]">
          {/* <img
            src={`${process.env.PUBLIC_URL}/banner/left-btn.png`}
            className="w-[15px] sm:w-[24px] object-contain left-[-40px] absolute"
            alt=""
          /> */}
          <div className="bg-white w-[260px] h-[86px] sm:w-[470px] sm:h-[120px] rounded-2xl items-center flex">
            <img
              src={`${process.env.PUBLIC_URL}/banner/ment01@2x.png`}
              className="h-[36px] sm:h-[48px] object-contain bg-white ml-[9.4%]"
              alt=""
            />
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/banner/main_top_menu_img02.png`}
                className="w-[123px] h-[113px] sm:w-[184px] sm:h-[173px] bottom-0 left-[58.6%] object-cover absolute"
                alt=""
              />
            </div>
          </div>
          {/* <img
            src={`${process.env.PUBLIC_URL}/banner/right-btn.png`}
            className="w-[15px] sm:w-[24px] object-contain right-[-40px] absolute"
            alt=""
          /> */}
        </div>

        <div className="h-full flex justify-center items-center relative">
          <div className="bg-white w-[260px] h-[86px] sm:w-[470px] sm:h-[120px] rounded-2xl items-center flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/banner/ment03@2x.png`}
              className="h-[36px] sm:h-[48px] object-contain bg-white"
              alt=""
            />
          </div>
        </div>
      </div>

      <InfiniteScroll
        dataLength={images.length}
        next={fetchData}
        hasMore={idxNumber.current < dataList.length}
        loader={<></>}
        className="justify-center items-center flex flex-col"
      >
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 grid-flow-dense auto-rows-auto gap-4 py-8 max-w-7xl px-4 xl:px-0">
          {images.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col justify-center rounded-[10px] overflow-hidden ${
                errorImages[item.main_image] ? "hidden" : ""
              }`}
              style={{
                gridRow: `span ${index % 2 === 0 ? 2 : 1}`,
              }}
            >
              <img
                src={
                  baseUrl +
                  (index % 2 === 0
                    ? "/long/" + item.main_image_long
                    : "/wide/" + item.main_image_wide)
                }
                alt={`Placeholder ${index + 1}`}
                className={`rounded-[10px] object-cover flex-1 w-full flex mb-[8px]`}
                onError={() => handleError(item.main_image)}
              />
              <div className="flex mb-2">
                <img
                  src={`${process.env.PUBLIC_URL}/project/icon-like.png`}
                  className="max-w-[18px] object-contain mr-[8px]"
                  alt="like"
                ></img>
                <div className="font-roboto font-extrabold text-sm text-[#464646]">
                  {item.like_count}
                </div>
              </div>
              <div className="font-roboto font-semibold text-[14px] text-[#bebebe]">
                {item.project_title}
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>

      <footer>
        <div className="bg-[#f2f4f8] px-4 pt-8 pb-6 text-[12px] font-roboto font-medium text-[#8d8c8c] flex flex-col md:items-center">
          <div className="md:flex md:max-w-[736px]">
            <div className="flex-1">
              <button>
                <img
                  src={`${process.env.PUBLIC_URL}/nav/icon-shapl-logo.svg`}
                  className="w-[102px]"
                  alt="Shapl"
                />
              </button>
              <p>©2024 SHAPL All Rights Reserved.</p>
              <br />
            </div>

            <div className="flex-1">
              <p>Customer Center : 1670 - 9840 (Mon-Fri, 10:00~17:00 KST)</p>
              <a href="mailto:support@shapl.com">support@shapl.com</a>
              <p>SHAPL Inc. CEO Changsoo Jin</p>
              <p>Business Registraion No. 689-81-00933 </p>
              <p>2020-서울마포-1410</p>
              <br />
            </div>

            <div className="flex-1">
              <strong>SOUTH KOREA</strong>
              <p>502,560, Samseong-ro, Gangnam-gu, Seoul, Republic of Korea</p>
            </div>
          </div>
          <div className="bg-[#dadada] w-full max-w-[736px] h-[1px] mb-2 hidden md:block"></div>

          <div className="font-AppleSDGothicNeo text-[12px] gap-4 hidden md:flex">
            <a href="/">디자이너 정책</a>
            <a href="/">이용 약관</a>
            <a href="/">반품 정책</a>
            <a href="/">개인정보 취급방침</a>
            <a href="/">저작권 정책</a>
          </div>
        </div>

        <div className="md:hidden w-[200px] h-[34px] border-y-[1px] border-l-[1px] border-[#bebebe] my-6 mx-4 bg-[#f2f4f8] font-semibold text-xs py-2 pl-[10px] text-[#8d8c8c] items-center flex">
          <p className="flex-1">SHAPL 정책</p>
          <img
            src={`${process.env.PUBLIC_URL}/footer/btn-up.svg`}
            alt="up"
          ></img>
        </div>
      </footer>
    </div>
  );
}

export default App;
